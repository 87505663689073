@import '../../../../styles/variables.scss';

.readingTimeInMinutes {
  margin-inline-start: var(--wds-space-400);

  .timerIcon {
    flex-shrink: 0;
    margin-inline-end: 2px;
  }

  .timeToRead {
    color: var(--wds-color-black-400);
    white-space: nowrap;

    @include mobile-only() {
      font-size: 12px !important;
    }
  }

  @include mobile-only() {
    margin-inline-start: 0;
  }
}
