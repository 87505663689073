.statusFR {
  &.collectingVotes {
    background-color: var(--wsr-color-B50);
  }

  &.planned {
    background-color: var(--wsr-color-P40);
  }

  &.inDev {
    background-color: var(--wsr-color-Y40);
  }

  &.preLaunch {
    background-color: #ffc58d;
  }

  &.released {
    background-color: var(--wsr-color-G40);
  }

  .statusText {
    color: var(--wsr-color-D20);
    white-space: nowrap;
  }
}
