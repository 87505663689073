.searchResultItem {
  @import './Tags/index.scss';
  @import './Tags/ReadingTime/index.scss';

  padding: var(--wds-space-300) 0;

  .thumbnailImage {
    min-width: 178px;
    margin: 0 var(--wds-space-200) 0 0;

    @include rtl() {
      margin: 0 0 0 var(--wds-space-200);
    }
  }

  .articleLink {
    text-decoration: none;
    width: 100%;
  }

  max-width: 936px;
  box-sizing: border-box;
  width: 100%;

  .topicBadge {
    &:hover {
      background-color: var(--wds-color-black-200) !important;
      border: var(--wds-color-black-200) !important;

      > span {
        color: var(--wds-color-white) !important;
      }
    }
  }

  .searchResultItemBody {
    .title {
      &.noDescription {
        margin-bottom: 0;
      }

      > div {
        svg {
          display: none;
        }
      }

      color: var(--wds-color-black-100);
    }

    &:hover {
      .title {
        > div {
          display: inline;

          svg {
            display: inline;
            vertical-align: middle;
          }
        }
      }
    }

    &:hover,
    &:focus {
      .title {
        color: var(--wds-color-blue-100);
      }
    }
  }

  @include mobile-only() {
    padding: var(--wds-space-400) 0 var(--wds-space-300);
    padding-inline-start: var(--wds-space-100);
    max-width: 100%;
  }

  .descriptionAndReadingTimeWrapper {
    width: 100%;
  }

  .title {
    width: 90%;

    @include mobile-only() {
      width: 100%;
    }
  }

  .title,
  .descriptionAndReadingTimeWrapper {
    @include mobile-only() {
      width: 100%;
    }

    .description {
      @include mobile-only() {
        font-size: 14px !important;
        line-height: 18px !important;
      }
    }

    &.title {
      margin: var(--wds-space-100) 0;

      > div {
        align-items: center;
      }

      @include Header5Bold();

      @include mobile-only() {
        @include Header6Bold();
      }
    }
  }
}
