.searchResultItemBadges {
  @import './CategoryTag/index.scss';
  @import './ETATag/index.scss';
  @import './StatusTag/index.scss';

  @include mobile-only() {
    padding-inline-end: var(--wds-space-100);
    box-sizing: border-box;
    align-self: stretch;
    overflow: auto;
  }

  .topicBadgeLink,
  .roadMapLink {
    display: flex;
    align-items: center;
    text-decoration: none;

    .topicBadge {
      margin-inline-end: var(--wds-space-200);

      &:hover,
      &:focus {
        background-color: var(--wds-color-black-200);
        border-color: var(--wds-color-black-200);

        > span {
          color: var(--wds-color-white) !important;
        }
      }

      > span {
        @include rtl() {
          font-size: 10px;
        }
      }
    }
  }

  .KnownIssuePageItemStatusDesktop {
    margin-left: var(--wds-space-100);
  }

  .typeTag {
    > span {
      letter-spacing: 0.5px;
      margin-bottom: 1px;

      > div {
        align-items: center;
      }
    }
  }

  .scrollableIndicator {
    position: absolute;
    box-sizing: content-box;
    right: var(--wds-space-300);
    content: '';
    width: var(--wds-space-300);
    height: 20px;
    z-index: 2;
    background: var(--wds-color-gradient-light-left-to-right);
    transform: rotate(-180deg);

    @include rtl() {
      left: 0;
      right: auto;
      background: var(--wds-color-gradient-light-right-to-left);
      transform: rotate(-90deg);
    }
  }
}
