@import './colors';
@import './typography';

$max-mobile-width: 767px;
$max-desktop-width: 1200px;

@mixin rtl() {
  &:lang(he) {
    @content;
  }
}

@mixin mobile-only() {
  @media screen and (max-width: $max-mobile-width) {
    @content;
  }
}

@mixin desktop-only() {
  @media screen and (min-width: $min-desktop-width) {
    @content;
  }
}

@mixin small-screen() {
  @media screen and (min-width: $min-desktop-width) and (max-width: $max-desktop-width) {
    @content;
  }
}

@mixin mobile-landscape() {
  @media screen and (max-width: $max-mobile-landscape-width) and (orientation: landscape) {
    @content;
  }
}
